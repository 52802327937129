import hero from "../../images/about-hero.jpg"
import header1 from "../../images/team_1.jpg"
import header2 from "../../images/team_2.jpg"
import header3 from "../../images/team_3.jpg"
import header4 from "../../images/team_4.jpg"
import header5 from "../../images/team_5.jpg"
import header6 from "../../images/team_6.jpg"
import header7 from "../../images/team_7.jpg"
import header8 from "../../images/team_8.jpg"

export const HeroModel = {
    image: hero,
    title: "We are clinical experts with international business acumen.",
    text: "With more than a century of combined experience in the pharmaceutical development, biotechnology, legal, corporate and high-tech sectors, our team has an unparalleled record of combining clinical expertise with international business acumen.",
}
export const AbouttitleModel = {
    title: "We are Jus-Pharma.",
    text1: "Jus-Pharma was created, developed and patented by Founder, Chairman & CEO Beatrice O’Brien, who has assembled a highly acclaimed international team with a combined expertise of more than 100 years in clinical trials research, health care, pharmaceutical engineering, biotechnology, legal practice, business management and technological innovation.",
    text2: "We created Jus-Pharma to revolutionize clinical trials management in a manner that will forever change the speed, effectiveness, safety and economics of the clinical trials process throughout the pharmaceutical, biotechnology and medical devices industries.",
}

export const AboutvalueModel = {
    maintitle: "Our Core Values",
    content1: [
        {
            title: "J",
            text: "Judiciously embrace and drive change. There is always a better way — and we will not rest until we find it!",
        },
        {
            title: "U",
            text: "Use data to drive decisions and results.",
        },
        {
            title: "S",
            text: "Set high expectations and make them happen!",
        },
    ],
    content2: [
        {
            title: "P",
            text: "Passion for Progress: We solve problems with an uncompromising focus on impact and excellence.",
        },
        {
            title: "H",
            text: "Honesty: We espouse transparency, direct communication and constructive feedback.",
        },
        {
            title: "A",
            text: "Always be innovative! We strive for excellence and are unconstrained by the status quo. We creatively approach every task to find novel solutions.",
        },
        {
            title: "R",
            text: "Results: We deliver outstanding outcomes by establishing a culture of exceptional execution and rewarding excellence.",
        },
        {
            title: "M",
            text: "Make a difference daily. We constantly push ourselves to be our best, focus on solutions, and make a positive impact.",
        },
        {
            title: "A",
            text: "Accountability: We measure ourselves against the highest standards of integrity and fiscal responsibility.",
        },
    ]
}

export const AboutteamModel = {
    title: "Our Team",
    content1: [
        {
            id: "1",
            header: header1,
            name: "Beatrice O'Brien",
            position: "Founder Chairman & CEO",
        },
        {
            id: "2",
            header: header2,
            name: "Kyle Hoedebecke",
            position: "Chief Medical Director",
     },
        {
            id: "3",
            header: header3,
            name: "Oliver Ramsbottom",
            position: "Advisory Board Member",
      },
        {
            id: "4",
            header: header4,
            name: "Duane Lakings",
            position: "Chief Scientific Officer",
      },
    ],
    content2: [
        {
            id: "5",
            header: header5,
            name: "Eyal Ron",
            position: "Sr. Managing Director, Product Development",
      },
        {
            id: "6",
            header: header6,
            name: "Evan Siegel",
            position: "Chief Regulatory Officer",
       },
        {
            id: "7",
            header: header7,
            name: "Scott G Grubin",
            position: "Senior Advisor",
        },
        {
            id: "8",
            header: header8,
            name: "Patricia Blaine",
            position: "Senior Medical Writer",
       },
    ],
}
