import React from 'react'
import { Image } from 'react-bootstrap'
import { HeroModel } from "./model"

const AboutHeroBanner = () => {
  return (
    <Image src={HeroModel.image} alt="about-hero" className="generalHerobanner"/>
  )
}

export default AboutHeroBanner