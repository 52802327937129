import React from "react"
import { Container, CardDeck, Card } from 'react-bootstrap'
import { Link } from 'gatsby-plugin-modal-routing'
import { AboutteamModel } from "./model"

const AboutteamModule = pros => {
    return (
        <div className="section_pad_st background_grad">
            <Container>
                <h4 className="main_title main_b_color mb-5 text-center">{AboutteamModel.title}</h4>
                <CardDeck className="margin_bot_35">
                    {AboutteamModel.content1.map((item, i) => {
                        return (
                            <Card className="text-center team_card" key={i}>
                                <Link to={`/team/team_detail${item.id}`} asModal>
                                    <div className="team_header mx-auto max_w_180">
                                        <Card.Img variant="top" src={item.header} alt={item.name}/>
                                    </div>
                                    <Card.Body>
                                        <Card.Title className="sub_title main_b_color pad_0">{item.name}</Card.Title>
                                        <Card.Text className="main_text main_g_color">{item.position}</Card.Text>
                                    </Card.Body>
                                </Link>
                            </Card>
                          )
                    })}
                </CardDeck>
                <CardDeck className="">
                    {AboutteamModel.content2.map((item, i) => {
                        return (
                            <Card className="text-center team_card" key={i}>
                                <Link to={`/team/team_detail${item.id}`} asModal>
                                    <div className="team_header mx-auto max_w_180">
                                        <Card.Img variant="top" src={item.header} alt={item.name}/>
                                    </div>
                                    <Card.Body>
                                        <Card.Title className="sub_title main_b_color pad_0">{item.name}</Card.Title>
                                        <Card.Text className="main_text main_g_color">{item.position}</Card.Text>
                                    </Card.Body>
                                </Link>
                            </Card>
                          )
                    })}
                </CardDeck>
            </Container>
        </div>
    )
}

export default AboutteamModule

