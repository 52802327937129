import React from "react"
import { Container, CardDeck, Card } from 'react-bootstrap'
import { AboutvalueModel } from "./model"

const AboutvalueModule = () => {
    return (
        <div className="section_pad_st1 background_grad">
            <Container>
                <h4 className="main_title main_b_color margin_bot_80 text-center">{AboutvalueModel.maintitle}</h4>
                <CardDeck className="des_max_width_600">
                    {AboutvalueModel.content1.map((item, i) => {
                        return (
                            <Card className="text-center value_card" key={i}>
                                <Card.Body className="value_pad">
                                    <div className="value_title">
                                        <h3 className="main_w_color pad_0">{item.title}</h3>
                                    </div>
                                    <Card.Text className="main_text main_b_color">{item.text}</Card.Text>
                                </Card.Body>
                            </Card>
                          )
                    })}
                </CardDeck>
                <CardDeck >
                    {AboutvalueModel.content2.map((item, i) => {
                        return (
                            <Card className="text-center value_card" key={i}>
                                <Card.Body className="value_pad">
                                    <div className="value_title">
                                        <h3 className="main_w_color pad_0">{item.title}</h3>
                                    </div>
                                    <Card.Text className="main_text main_b_color">{item.text}</Card.Text>
                                </Card.Body>
                            </Card>
                          )
                    })}
                </CardDeck>
                
            </Container>
        </div>
    )
}

export default AboutvalueModule

