import React from "react"
import { Container, Row, Col } from 'react-bootstrap'
import { AbouttitleModel } from "./model"

const AbouttitleModule = () => {
    return (
        <div className="section_pad_st1">
            <Container>
                <Row className="margin_0">
                    <Col
                        className="col-sm-5 pad_r_35"
                    >
                        <h4 className="main_title main_b_colorv main_b_color">{AbouttitleModel.title}</h4>
                    </Col>
                    <Col
                        className="col-sm-7 pad_l_35"
                    >
                        <p className="main_text main_b_color">{AbouttitleModel.text1}</p>
                        <p className="main_text main_b_color">{AbouttitleModel.text2}</p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default AbouttitleModule

