import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "react-bootstrap"

import AboutImage from "../components/aboutus/aboutusImage"
import AboutboxModule from "../components/aboutus/aboutboxModule"
import AbouttitleModule from"../components/aboutus/abouttitleModule"
import AboutvalueModule from "../components/aboutus/aboutvalueModule"
import AboutteamModule from "../components/aboutus/aboutteamModule"
import CTAModule from "../components/common/ctaModule"

const AboutPage = () => (
  <Layout>
        <SEO title="About" />
        <Container className="pad_header">
            <h1 className="header_title">About Us</h1>
        </Container>
        <AboutImage />
        <AboutboxModule />
        <AbouttitleModule />
        <AboutvalueModule />
        <AboutteamModule />
        <CTAModule 
            title="Connect with a Jus-Pharma team member today!"
            css='background_e'
        />
  </Layout>
)

export default AboutPage

