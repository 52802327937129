import React from "react"
import { Container, Row, Col } from 'react-bootstrap'
import { HeroModel } from "./model"

const AboutBoxModule = () => {
    return (
        <Container className="margin_bot_80">
        <div className="jus_box_st mt-150">
            <Row className="margin_0">
                <Col
                    className="background_mask_blue col-sm-6 pad_65"
                >
                    <h4 className="main_title main_w_color">{HeroModel.title}</h4>
                </Col>
                <Col
                    className="background_white col-sm-6 pad_65"
                >
                    <div className="align-self-center pad_bt_15">
                        <p className="main_text main_b_color">{HeroModel.text}</p>
                    </div>
                </Col>
            </Row>
        </div>
        
        </Container>
    )
}

export default AboutBoxModule